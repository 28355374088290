import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import veg1 from './../../../../images/veg1.jpeg';
export const _frontmatter = {
  "title": "Vegita",
  "path": "/Vegetainfo.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img className="mx-auto" src={veg1} />
    <p><strong parentName="p">{`SON VEGITA`}</strong></p>
    <p>{`Vegita is sorta of a... well, he has an anger on people who always been stronger than him. He loves to kill and took many innocent people's lives. He becomes very good in Dragonball GT. Vegita hates Gokou the most because he's always stronger than him. He never wants help. Reason? Simple. He's the Saiyjin Prince. He wants to proove he's the best, because he's the king's son.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      